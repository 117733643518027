<template>
  <div class="technology-products">
    <div class="top-banner">
      <img
        class="pc"
        :src="require('../assets/image/product_banner.webp')"
        alt=""
        @load="imgLoad"
      />
      <img
        class="h5"
        :src="require('../assets/image/iphone/product_banner.png')"
        alt=""
      />
      <div class="base-container">
        <div class="banner-title">产品服务</div>
      </div>
    </div>
    <div class="nav-bar-wrapper">
      <div class="nav-bar" id="nav-bar-id">
        <div class="base-container">
          <div class="nav-bar-list-wrapper">
            <div
              :class="`nav-bar-list ${
                activeKey === item.key ? 'nav-bar-list-active' : ''
              }`"
              v-for="item in navBarList"
              :key="item.key"
              @click="navBarClick(item.key)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="base-container technology-products-container">
      <div class="consult-wrapper" id="consult">
        <div class="base-title">咨询辅导</div>
        <div class="consult-list-wrapper">
          <div
            class="consult-list"
            v-for="item in consultList"
            :key="item.title"
          >
            <div class="list-icon">
              <img :src="item.icon" alt="" />
            </div>
            <div class="list-title">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="product-wrapper" id="product">
        <div class="base-title">科技产品</div>
        <div class="product-tab-wrapper">
          <div class="product-tab-left">
            <div
              :class="`tab-left-list ${
                activeProductTabKey === item.key ? 'active-tab' : ''
              }`"
              v-for="item in productTab"
              :key="item.title"
              @mouseenter="handlerHover(item.key)"
            >
              <div class="list-left">
                <div class="list-active-icon">
                  <img :src="item.activeIcon" alt="" />
                </div>
                <div class="list-icon">
                  <img :src="item.icon" alt="" />
                </div>
              </div>
              <div class="list-right">
                <div class="list-right-title">{{ item.title }}</div>
              </div>
            </div>
          </div>
          <div class="product-tab-right">
            <div
              class="product-right-box blades"
              v-show="activeProductTabKey === 'blades'"
            >
              <div class="blades-list-wrapper">
                <div class="blades-list">
                  <div class="blades-list-title">流水分析</div>
                  <div class="blades-list-desc">
                    <div>文件导入或OCR识别多平台交易流水，</div>
                    <div>数据引擎分析潜在风险并出具流水报告</div>
                  </div>
                </div>
                <div class="blades-list">
                  <div class="blades-list-title">征信报告</div>
                  <div class="blades-list-desc">
                    <div>多维度，多角度分析客户征信报告，</div>
                    <div>针对客户征信形成智能化报告并提示客户风险</div>
                  </div>
                </div>
                <div class="blades-list">
                  <div class="blades-list-title">三方数据</div>
                  <div class="blades-list-desc">
                    <div>结合多种数据源数据，</div>
                    <div>
                      形成客户画像并经大量实际业务训练，降低业务风险敞口
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="product-right-box groove-admin"
              v-show="activeProductTabKey === 'groove-admin'"
            >
              <div class="right-box-content">
                <div class="groove-admin-desc">汇聚小微业务特色与专家经验</div>
                <div class="public-desc">
                  专注小微信贷业务，融合人工智能技术与专家经验，采用"人机结合"的设计理念，统一管理产品、数据、风控决策、客户和业务信息，构建银行业全流程智能化微贷业务系统解决方案。
                </div>
              </div>
            </div>
            <div
              class="product-right-box groove-h5"
              v-show="activeProductTabKey === 'groove-h5'"
            >
              <div class="groove-h5-desc">针对小微业务的PAD端工作台</div>
            </div>
            <div
              class="product-right-box joint"
              v-show="activeProductTabKey === 'joint'"
            >
              <div class="joint-desc">
                <div>多场景进行数据联合分析建模</div>
                <div>补充并优化当前风控模型</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="operate-wrapper" id="operate">
        <div class="base-title">联合运营</div>
        <div class="operate-list-wrapper">
          <div
            class="operate-list"
            v-for="item in operateList"
            :key="item.title"
          >
            <div class="list-icon">
              <img :src="item.icon" alt="" />
            </div>
            <div class="list-title">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TechnologyProducts",
  data() {
    return {
      activeKey: "consult",
      navBarList: [
        {
          title: "咨询辅导",
          key: "consult",
        },
        {
          title: "科技产品",
          key: "product",
        },
        {
          title: "联合运营",
          key: "operate",
        },
      ],
      consultList: [
        {
          title: "小微中心打造",
          icon: require("../assets/image/consult_list1.png"),
        },
        {
          title: "业务风控咨询",
          icon: require("../assets/image/consult_list2.png"),
        },
        {
          title: "信贷产品设计",
          icon: require("../assets/image/consult_list3.png"),
        },
        {
          title: "小微团队培养",
          icon: require("../assets/image/consult_list4.png"),
        },
        {
          title: "场景金融获客",
          icon: require("../assets/image/consult_list5.png"),
        },
        {
          title: "不良催收咨询",
          icon: require("../assets/image/consult_list6.png"),
        },
      ],
      operateList: [
        {
          title: "全体系本土化落地",
          icon: require("../assets/image/operate_list1.png"),
        },
        {
          title: "专家业务培训",
          icon: require("../assets/image/operate_list2.png"),
        },
        {
          title: "专家团队长期全流程辅导",
          icon: require("../assets/image/operate_list3.png"),
        },
        {
          title: "科技系统辅助支撑",
          icon: require("../assets/image/operate_list4.png"),
        },
        {
          title: "搭建和培养小微经营团队",
          icon: require("../assets/image/operate_list5.png"),
        },
        {
          title: "沉淀优质小微资产",
          icon: require("../assets/image/operate_list6.png"),
        },
      ],
      navBarHeight: 0,
      productTab: [
        {
          activeIcon: require("../assets/image/product_active_icon1.png"),
          icon: require("../assets/image/product_icon1.png"),
          title: "湛泸智能风控工具",
          key: "blades",
        },
        {
          activeIcon: require("../assets/image/product_active_icon2.png"),
          icon: require("../assets/image/product_icon2.png"),
          title: "智能微贷平台",
          key: "groove-admin",
        },
        {
          activeIcon: require("../assets/image/product_active_icon3.png"),
          icon: require("../assets/image/product_icon3.png"),
          title: "移动端工作台",
          key: "groove-h5",
        },
        {
          activeIcon: require("../assets/image/product_active_icon4.png"),
          icon: require("../assets/image/product_icon4.png"),
          title: "联合建模",
          key: "joint",
        },
      ],
      activeProductTabKey: "blades",
      domScrollTop: 0,
      navBarScrollTop: 0,
    };
  },
  mounted() {
    this.navBarHeight = document.getElementById("nav-bar-id").offsetHeight;
    // 获取当前锚点所有的dom
    const boxListDom = this.navBarList.map((item) =>
      document.getElementById(item.key)
    );
    // 获取当前锚点所有的scrollTop
    const boxListTop = boxListDom.map((item) =>
      Math.floor(
        item.getBoundingClientRect().top +
          this.getScrollTop() -
          this.navBarHeight
      )
    );
    this.domScrollTop = boxListTop;
    window.addEventListener("scroll", this.scrollChange);
  },
  methods: {
    imgLoad() {
      // 获取小的navbar具体顶部的距离
      this.navBarScrollTop =
        document.getElementById("nav-bar-id").getBoundingClientRect().top +
        this.getScrollTop();
    },
    scrollChange() {
      let scrollTop = this.getScrollTop();
      // 根据滚动高度大导航栏消失
      if (scrollTop > 80) {
        let topNavBarClass = document.getElementById("top-nav-bar").className;
        if (topNavBarClass.indexOf("hidden-nav-bar") === -1) {
          document.getElementById("top-nav-bar").className += " hidden-nav-bar";
        }
      } else {
        document.getElementById("top-nav-bar").className = "nav-bar";
      }
      // 根据滚动高度小导航栏绝对定位
      if (scrollTop > this.navBarScrollTop) {
        const currentNavBarClass =
          document.getElementById("nav-bar-id").className;
        if (currentNavBarClass.indexOf("fixed-nav-bar") === -1) {
          document.getElementById("nav-bar-id").className += " fixed-nav-bar";
        }
      } else {
        document.getElementById("nav-bar-id").className = "nav-bar";
      }
      if (scrollTop > 0 && scrollTop < this.domScrollTop[1]) {
        this.activeKey = this.navBarList[0].key;
      } else if (
        scrollTop >= this.domScrollTop[1] &&
        scrollTop < this.domScrollTop[2]
      ) {
        this.activeKey = this.navBarList[1].key;
      } else if (scrollTop >= this.domScrollTop[2]) {
        this.activeKey = this.navBarList[2].key;
      }
    },
    getScrollTop() {
      let scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      return scrollTop;
    },
    navBarClick(key) {
      this.activeKey = key;
      let scrollTop =
        this.getScrollTop() +
        document.getElementById(key).getBoundingClientRect().top;
      document.documentElement.scrollTop = scrollTop - this.navBarHeight;
      document.body.scrollTop = scrollTop - this.navBarHeight;
    },
    handlerHover(key) {
      this.activeProductTabKey = key;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollChange);
  },
};
</script>

<style scoped lang="scss">
.technology-products {
  .top-banner {
    width: 100%;
    position: relative;
    font-size: 0px;
    img {
      width: 100%;
      height: auto;
    }
    .base-container {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
    }
    .banner-title {
      font-size: 48px;
      font-weight: bold;
      color: #4782f7;
      line-height: 52px;
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
    }
  }
  .technology-products-container {
    .base-title {
      font-size: 36px;
      font-weight: bold;
      color: #333333;
      line-height: 50px;
      text-align: center;
      margin-top: 80px;
      margin-bottom: 48px;
    }
    .consult-wrapper,
    .operate-wrapper {
      .consult-list-wrapper,
      .operate-list-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .consult-list,
        .operate-list {
          width: 387px;
          height: 248px;
          background: #ffffff;
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;
          &:nth-child(n + 4) {
            margin-bottom: 0px;
          }
          .list-icon {
            width: 150px;
            height: 150px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .list-title {
            font-size: 26px;
            font-weight: bold;
            color: #333333;
            line-height: 36px;
            margin-top: 22px;
          }
        }
      }
    }
    .operate-wrapper {
      margin-bottom: 80px;
    }
    .product-wrapper {
      .product-tab-wrapper {
        display: flex;
        .product-tab-left {
          width: 380px;
          .tab-left-list {
            width: 380px;
            height: 140px;
            border: 1px solid #e7e7e7;
            display: flex;
            align-items: center;
            padding: 0px 0px 0px 52px;
            box-sizing: border-box;
            .list-left {
              .list-active-icon,
              .list-icon {
                width: 29px;
                height: 29px;
                margin-right: 22px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .list-active-icon {
                display: none;
              }
            }
            .list-right {
              font-size: 26px;
              font-weight: bold;
              color: #333333;
              line-height: 26px;
            }
          }
          .active-tab {
            background: #4782f7;
            .list-left {
              .list-active-icon {
                display: block;
              }
              .list-icon {
                display: none;
              }
            }
            .list-right {
              color: white;
            }
          }
        }
        .product-tab-right {
          flex: 1;
          overflow: hidden;
          .product-right-box {
            width: 100%;
            height: 100%;
            background-size: 100% 100%;
            .public-desc {
              font-size: 16px;
              font-weight: 400;
              color: #787878;
              line-height: 26px;
              width: 348px;
              margin-top: 10px;
            }
          }
          .right-box-content {
            padding: 0px 57px;
          }
          .blades {
            background-image: url("../assets/image/blades_bg.png");
            .blades-list-wrapper {
              padding-top: 45px;
              .blades-list {
                padding: 28px 57px;
                margin-bottom: 22px;
                &:last-child {
                  margin-bottom: 0px;
                }
                .blades-list-title {
                  font-size: 26px;
                  font-weight: bold;
                  color: #4782f7;
                  line-height: 26px;
                  margin-bottom: 10px;
                }
                .blades-list-desc {
                  font-size: 16px;
                  font-weight: 400;
                  color: #787878;
                  line-height: 26px;
                }
              }
            }
          }
          .groove-admin {
            background-image: url("../assets/image/groove_admin_bg.png");
            .groove-admin-desc {
              padding: 58px 0px 0px;
              font-size: 26px;
              font-weight: bold;
              color: #4782f7;
              line-height: 26px;
            }
          }
          .groove-h5 {
            background-image: url("../assets/image/groove_h5_bg.png");
            .groove-h5-desc {
              padding: 58px 57px;
              font-size: 26px;
              font-weight: bold;
              color: #4782f7;
              line-height: 26px;
            }
          }
          .joint {
            background-image: url("../assets/image/joint_bg.png");
            .joint-desc {
              padding: 58px 57px;
              font-size: 26px;
              font-weight: bold;
              color: #4782f7;
              line-height: 26px;
              > div {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
  .h5 {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .technology-products {
    .top-banner {
      position: relative;
      width: 100%;
      .banner-title {
        font-size: 123px;
        font-weight: bold;
        line-height: 133px;
        margin-left: 115px;
      }
    }
    .nav-bar-wrapper {
      display: none;
    }
    .technology-products-container {
      .base-title {
        font-size: 82px;
        line-height: 123px;
        margin-top: 154px;
        margin-bottom: 105px;
      }
      .consult-wrapper,
      .operate-wrapper {
        .consult-list-wrapper,
        .operate-list-wrapper {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .consult-list,
          .operate-list {
            width: 858px;
            height: 635px;
            margin-bottom: 51px;
            .list-icon {
              width: 384px;
              height: 384px;
            }
            .list-title {
              font-size: 67px;
              line-height: 78px;
              margin-top: 56px;
            }
          }
        }
      }
      .operate-wrapper {
        margin-bottom: 154px;
      }
      .product-wrapper {
        .product-tab-wrapper {
          display: flex;
          flex-direction: column;
          .product-tab-left {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .tab-left-list {
              width: 50%;
              height: 358px;
              padding: 0px 0px 0px 100px;
              box-sizing: border-box;
              &:nth-child(2n) {
                border-left: none;
              }
              &:nth-child(-n + 2) {
                border-bottom: 0px;
              }
              .list-left {
                .list-active-icon,
                .list-icon {
                  width: 102px;
                  height: 102px;
                  margin-right: 40px;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .list-active-icon {
                  display: none;
                }
              }
              .list-right {
                font-size: 67px;
                line-height: 67px;
              }
            }
            .active-tab {
              background: #4782f7;
              .list-left {
                .list-active-icon {
                  display: block;
                }
                .list-icon {
                  display: none;
                }
              }
              .list-right {
                color: white;
              }
            }
          }
          .product-tab-right {
            overflow: hidden;
            width: 100%;
            height: 1434px;
            flex: none;
            .product-right-box {
              width: 100%;
              height: 100%;
              background-size: 100% 100%;
              .public-desc {
                font-size: 55px;
                line-height: 70px;
                width: 900px;
                margin-top: 30px;
              }
            }
            .right-box-content {
              padding: 0px 174px;
            }
            .blades {
              background-image: url("../assets/image/blades_bg.png");
              .blades-list-wrapper {
                padding-top: 45px;
                .blades-list {
                  padding: 100px 174px;
                  margin-bottom: 22px;
                  &:last-child {
                    margin-bottom: 0px;
                  }
                  .blades-list-title {
                    font-size: 67px;
                    line-height: 67px;
                    margin-bottom: 20px;
                  }
                  .blades-list-desc {
                    font-size: 55px;
                    line-height: 70px;
                  }
                }
              }
            }
            .groove-admin {
              background-image: url("../assets/image/groove_admin_bg.png");
              .groove-admin-desc {
                font-size: 67px;
                line-height: 67px;
                padding: 148px 0px 0px 0px;
              }
            }
            .groove-h5 {
              background-image: url("../assets/image/groove_h5_bg.png");
              .groove-h5-desc {
                font-size: 67px;
                line-height: 67px;
                padding: 148px 0px 0px 174px;
              }
            }
            .joint {
              background-image: url("../assets/image/joint_bg.png");
              .joint-desc {
                font-size: 67px;
                line-height: 67px;
                padding: 148px 0px 0px 174px;
                > div {
                  margin-bottom: 30px;
                }
              }
            }
          }
        }
      }
    }
    .h5 {
      display: block;
    }
    .pc {
      display: none;
    }
  }
}
</style>
