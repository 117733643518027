var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"technology-products"},[_c('div',{staticClass:"top-banner"},[_c('img',{staticClass:"pc",attrs:{"src":require('../assets/image/product_banner.webp'),"alt":""},on:{"load":_vm.imgLoad}}),_c('img',{staticClass:"h5",attrs:{"src":require('../assets/image/iphone/product_banner.png'),"alt":""}}),_vm._m(0)]),_c('div',{staticClass:"nav-bar-wrapper"},[_c('div',{staticClass:"nav-bar",attrs:{"id":"nav-bar-id"}},[_c('div',{staticClass:"base-container"},[_c('div',{staticClass:"nav-bar-list-wrapper"},_vm._l((_vm.navBarList),function(item){return _c('div',{key:item.key,class:`nav-bar-list ${
              _vm.activeKey === item.key ? 'nav-bar-list-active' : ''
            }`,on:{"click":function($event){return _vm.navBarClick(item.key)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)])])]),_c('div',{staticClass:"base-container technology-products-container"},[_c('div',{staticClass:"consult-wrapper",attrs:{"id":"consult"}},[_c('div',{staticClass:"base-title"},[_vm._v("咨询辅导")]),_c('div',{staticClass:"consult-list-wrapper"},_vm._l((_vm.consultList),function(item){return _c('div',{key:item.title,staticClass:"consult-list"},[_c('div',{staticClass:"list-icon"},[_c('img',{attrs:{"src":item.icon,"alt":""}})]),_c('div',{staticClass:"list-title"},[_vm._v(" "+_vm._s(item.title)+" ")])])}),0)]),_c('div',{staticClass:"product-wrapper",attrs:{"id":"product"}},[_c('div',{staticClass:"base-title"},[_vm._v("科技产品")]),_c('div',{staticClass:"product-tab-wrapper"},[_c('div',{staticClass:"product-tab-left"},_vm._l((_vm.productTab),function(item){return _c('div',{key:item.title,class:`tab-left-list ${
              _vm.activeProductTabKey === item.key ? 'active-tab' : ''
            }`,on:{"mouseenter":function($event){return _vm.handlerHover(item.key)}}},[_c('div',{staticClass:"list-left"},[_c('div',{staticClass:"list-active-icon"},[_c('img',{attrs:{"src":item.activeIcon,"alt":""}})]),_c('div',{staticClass:"list-icon"},[_c('img',{attrs:{"src":item.icon,"alt":""}})])]),_c('div',{staticClass:"list-right"},[_c('div',{staticClass:"list-right-title"},[_vm._v(_vm._s(item.title))])])])}),0),_c('div',{staticClass:"product-tab-right"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeProductTabKey === 'blades'),expression:"activeProductTabKey === 'blades'"}],staticClass:"product-right-box blades"},[_vm._m(1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeProductTabKey === 'groove-admin'),expression:"activeProductTabKey === 'groove-admin'"}],staticClass:"product-right-box groove-admin"},[_vm._m(2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeProductTabKey === 'groove-h5'),expression:"activeProductTabKey === 'groove-h5'"}],staticClass:"product-right-box groove-h5"},[_c('div',{staticClass:"groove-h5-desc"},[_vm._v("针对小微业务的PAD端工作台")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeProductTabKey === 'joint'),expression:"activeProductTabKey === 'joint'"}],staticClass:"product-right-box joint"},[_vm._m(3)])])])]),_c('div',{staticClass:"operate-wrapper",attrs:{"id":"operate"}},[_c('div',{staticClass:"base-title"},[_vm._v("联合运营")]),_c('div',{staticClass:"operate-list-wrapper"},_vm._l((_vm.operateList),function(item){return _c('div',{key:item.title,staticClass:"operate-list"},[_c('div',{staticClass:"list-icon"},[_c('img',{attrs:{"src":item.icon,"alt":""}})]),_c('div',{staticClass:"list-title"},[_vm._v(" "+_vm._s(item.title)+" ")])])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-container"},[_c('div',{staticClass:"banner-title"},[_vm._v("产品服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blades-list-wrapper"},[_c('div',{staticClass:"blades-list"},[_c('div',{staticClass:"blades-list-title"},[_vm._v("流水分析")]),_c('div',{staticClass:"blades-list-desc"},[_c('div',[_vm._v("文件导入或OCR识别多平台交易流水，")]),_c('div',[_vm._v("数据引擎分析潜在风险并出具流水报告")])])]),_c('div',{staticClass:"blades-list"},[_c('div',{staticClass:"blades-list-title"},[_vm._v("征信报告")]),_c('div',{staticClass:"blades-list-desc"},[_c('div',[_vm._v("多维度，多角度分析客户征信报告，")]),_c('div',[_vm._v("针对客户征信形成智能化报告并提示客户风险")])])]),_c('div',{staticClass:"blades-list"},[_c('div',{staticClass:"blades-list-title"},[_vm._v("三方数据")]),_c('div',{staticClass:"blades-list-desc"},[_c('div',[_vm._v("结合多种数据源数据，")]),_c('div',[_vm._v(" 形成客户画像并经大量实际业务训练，降低业务风险敞口 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-box-content"},[_c('div',{staticClass:"groove-admin-desc"},[_vm._v("汇聚小微业务特色与专家经验")]),_c('div',{staticClass:"public-desc"},[_vm._v(" 专注小微信贷业务，融合人工智能技术与专家经验，采用\"人机结合\"的设计理念，统一管理产品、数据、风控决策、客户和业务信息，构建银行业全流程智能化微贷业务系统解决方案。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"joint-desc"},[_c('div',[_vm._v("多场景进行数据联合分析建模")]),_c('div',[_vm._v("补充并优化当前风控模型")])])
}]

export { render, staticRenderFns }